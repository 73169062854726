@use 'fonts' as *;

ion-chip {
  @include body-s;

  line-height: 1.2em;
  min-height: 2.625rem;
  min-width: 2.625rem;
  background: var(--ion-background-color);
  color: var(--ion-color-text);
  border-radius: 1.3rem;
  pointer-events: none;
  padding: 2px 8px;

  &:has(ion-badge) {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  ion-icon {
    min-width: 1.5rem;
    padding: 0.313rem;
    margin-inline-end: 4px;
    border-radius: 50%;
    pointer-events: inherit;
    background: var(--ion-color-base);
    color: var(--ion-color-contrast);

    &.ion-color-mood {
      background: var(--ion-color-contrast);
      color: var(--ion-color-base);
    }
  }

  &.chip--iconOnly {
    padding: 4px;

    ion-icon {
      margin: 0;
    }
  }

  ion-badge {
    @include body-s;

    width: 2.286em;
    height: 2.286em;
    padding: 0;
    margin-left: -4px;
    border-radius: 50%;
    margin-right: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: var(--box-color);
    color: var(--ion-text-color);
  }
}
