/** Shared stuff **/
@mixin title {
  letter-spacing: -0.02em;
  font-weight: 800;
  line-height: 1.35em;
}

@mixin body {
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0;
}

@mixin button {
  text-transform: none;
  letter-spacing: 0;
  font-weight: 500;
}

@mixin font-size-10 {
  font-size: 0.625rem; // 10px
}

@mixin font-size-12 {
  font-size: 0.75rem; // 12px
}

@mixin font-size-14 {
  font-size: 0.875rem; // 14px
}

@mixin font-size-16 {
  font-size: 1rem; // 16px
}

@mixin font-size-18 {
  font-size: 1.125rem; // 18px
}

@mixin font-size-20 {
  font-size: 1.25rem; // 20px
}

@mixin font-size-22 {
  font-size: 1.375rem; // 22px
}

@mixin font-size-24 {
  font-size: 1.5rem; // 24px
}

@mixin font-size-28 {
  font-size: 1.75rem; // 28px
}

@mixin font-size-36 {
  font-size: 2.25rem; // 36px
}

/** Font size packages **/
@mixin body-2xs {
  @include body;
  @include font-size-10;
}

@mixin body-xs {
  @include body;
  @include font-size-12;
}

@mixin body-s {
  @include body;
  @include font-size-14;
}

@mixin body-m {
  @include body;
  @include font-size-16;
}

@mixin body-l {
  @include body;
  @include font-size-18;
}

@mixin subtitle-s {
  @include title;
  @include font-size-14;
}

@mixin subtitle-m {
  @include title;
  @include font-size-16;
}

@mixin subtitle-l {
  @include title;
  @include font-size-18;
}

@mixin title-s {
  @include title;
  @include font-size-18;
}

@mixin title-m {
  @include title;
  @include font-size-20;
}

@mixin title-l {
  @include title;
  @include font-size-22;
}

@mixin title-xl {
  @include title;
  @include font-size-28;
}

@mixin title-2xl {
  @include title;
  @include font-size-36;
}

@mixin button-s {
  @include button;
  @include font-size-14;
}

@mixin button-m {
  @include button;
  @include font-size-16;
}

/** MONO **/
@mixin mono {
  font-family: 'DM Mono', monospace;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 400;
}

@mixin mono-xs {
  @include mono;
  @include font-size-12;
}

@mixin mono-s {
  @include mono;
  @include font-size-14;
}

@mixin mono-m {
  @include mono;
  @include font-size-16;
}

@mixin mono-l {
  @include mono;
  @include font-size-28;
}

@mixin button-numpad {
  @include button;
  @include font-size-18;
}

@mixin button-numpad-s {
  @include button;
  @include font-size-14;
}
