@use 'fonts' as *;

.tooltip {
  position: absolute;
  z-index: 99999;
  padding: 0 8px;
  width: 100%;
  max-width: 300px;
  opacity: 0;
  transition:
    opacity 0.2s,
    transform 0.2s;
  backface-visibility: hidden;

  &.open {
    opacity: 1;
  }

  &--topleft {
    transform: translate3d(-50%, -85%, 0);

    &.open {
      transform: translate3d(-50%, -100%, 0);
    }
  }

  &--topright {
    transform: translate3d(50%, -85%, 0);

    &.open {
      transform: translate3d(50%, -100%, 0);
    }
  }

  &--bottomleft {
    transform: translate3d(-50%, 15%, 0);

    &.open {
      transform: translate3d(-50%, 0, 0);
    }
  }

  &--bottomright {
    transform: translate3d(50%, 15%, 0);

    &.open {
      transform: translate3d(50%, 0, 0);
    }
  }

  &--small {
    width: auto;
    white-space: nowrap;
  }

  &--medium {
    .tooltip__inner,
    .tooltip__arrow {
      background-color: var(--ion-color-medium);
      color: var(--ion-color-black);
    }
  }
}

.tooltip__inner {
  @include body-s;

  background: var(--ion-color-logo);
  color: var(--ion-color-logo-contrast);
  padding: 14px 16px;
  border-radius: 2px;
  text-align: left;
}

.tooltip__arrow {
  background-color: var(--ion-color-logo);
  position: absolute;
  top: -10px;
  text-align: left;
  display: block;
  border-top-right-radius: 5px;
  transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
  width: 10px;
  height: 10px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 10px;
    height: 10px;
  }

  &::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }

  &::after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }

  &--top {
    bottom: -10px;
    top: auto;
    transform: rotate(120deg) skewX(-30deg) scale(1, 0.866);
  }
}

page-add-data {
  .tooltip__arrow,
  .tooltip__inner {
    background: var(--ion-color-lightGray);
    color: var(--ion-color-darkPurple);
  }
}
