@use 'fonts' as *;

ion-toast {
  &.snackbar {
    @include body-m;

    --start: 16px;
    --end: 16px;
    --button-color: var(--ion-color-primary);
    --footer-height: 54px;
  }

  &.snackbar--noFooter {
    --footer-height: 0px;
  }

  &::part(button) {
    @include button-s;

    text-transform: none;
    max-width: 150px;
    text-align: right;
  }

  &::part(message) {
    @include body-m;
  }
}
