@use 'mixins' as *;

ion-toggle {
  --track-background: transparent;
  --ion-color-step-50: transparent;
  --handle-background: var(--ion-color-white);
  --handle-background-checked: var(--ion-color-white);

  &::part(track) {
    box-shadow: inset 0 0 1px 1px var(--ion-border-color);
  }

  &.toggle-checked::part(track) {
    box-shadow: var(--background-checked);
  }

  &:focus {
    &::before {
      @include toggle-focus-outline;

      --margin: 0;
    }
  }
}
