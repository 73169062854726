/****************************************************
* Colors
****************************************************/

@mixin color($name, $color, $rgb, $contrast, $contrastRgb, $shade, $tint) {
  --ion-color-#{$name}: #{$color}; // base color
  --ion-color-#{$name}-rgb: #{$rgb}; // base color in rgb
  --ion-color-#{$name}-contrast: #{$contrast}; // contrast color
  --ion-color-#{$name}-contrast-rgb: #{$contrastRgb}; // contrast color in rgb
  --ion-color-#{$name}-shade: #{$shade}; // darker shade of base color
  --ion-color-#{$name}-tint: #{$tint}; // lighter shade of base color
}

/* App colors */

$primaryColorLight: #488ee8;
$primaryColorLightRgb: 72, 142, 232;
$primaryColorLightShade: #2674d9;
$primaryColorLightShadeRgb: 38, 116, 217;
// $primaryColorLightTint: ;
// $primaryColorLightTintRgb: ;
$primaryColorDark: #7fb7ff;
$primaryColorDarkRgb: 127, 183, 255;
$primaryColorDarkShade: #7fb7ff;
$primaryColorDarkShadeRgb: 127, 183, 255;
// $primaryColorDarkTint: ;
// $primaryColorDarkTintRgb: ;

$secondaryColorLight: #72035d;
$secondaryColorLightRgb: 114, 3, 93;
$secondaryColorLightShade: #670354;
$secondaryColorLightShadeRgb: 103, 3, 84;
$secondaryColorLightTint: #60034e;
$secondaryColorLightTintRgb: 96, 3, 78;
$secondaryColorDark: #f796ee;
$secondaryColorDarkRgb: 247, 150, 238;
$secondaryColorDarkShade: #de87d6;
$secondaryColorDarkShadeRgb: 222, 135, 214;
$secondaryColorDarkTint: #cf7ec8;
$secondaryColorDarkTintRgb: 207, 126, 200;

$accentColor: #ffd7d6;
$accentColorRgb: 255, 215, 214;
$accentColorShade: #fa837f;
$accentColorShadeRgb: 250, 131, 127;
$accentColorTint: #ffe8e8;
$accentColorTintRgb: 255, 232, 232;

/* Neutral colors */

$whiteColor: #fff;
$whiteColorRgb: 255, 255, 255;

$grayColor: #a3a3a3;
$grayColorRgb: 163, 163, 163;

$blackColor: #1a1a1a;
$blackColorRgb: 26, 26, 26;

$keyNeutralColor: #f5f3f3;
$keyNeutralColorRgb: 245, 243, 243;

$support1NeutralColor: #f1eaea;
$support1NeutralColorRgb: 242, 235, 235;

$support2NeutralColor: #e2d4d4;
$support2NeutralColorRgb: 226, 212, 212;

$backgroundColorDark: #310629;
$backgroundColorDarkRgb: 49, 6, 41;

$toolbarBackgroundColorDark: #4a2d47;
$toolbarBackgroundColorDarkRgb: 74, 45, 71;

$predictionColor: #757575;
$predictionColorRgb: 117, 117, 117;

$placeholderGrayColor: #5f5f5f;
$placeholderGrayColorRgb: 95, 95, 95;

$boxBackgroundColorDark: #421a3a;
$boxBackgroundColorDarkRgb: rgb(65, 26, 57);

$lightBlueColor: #cce2ff;
$lightBlueColorRgb: 204, 226, 255;

$darkPurpleColor: #560246;
$darkPurpleColorRgb: 86, 2, 70;

/* Alert colors */

$greenAlertColor: #87b738;
$greenAlertColorRgb: 135, 183, 56;

$yellowAlertColor: #ffbd00;
$yellowAlertColorRgb: 255, 189, 0;

$redAlertColorLight: #d83030;
$redAlertColorLightRgb: 216, 48, 48;
$redAlertColorDark: #ff7c7c;
$redAlertColorDarkRgb: 255, 124, 124;

$warningAlertColor: #ff9000;
$warningAlertColorRgb: 255, 144, 0;

$warningPinkColor: #fcebeb;
$warningPinkColorRgb: 252, 235, 235;

/* Fertility colors */

$notFertilePreventFertilityColor: #a1c862;
$notFertilePreventFertilityColorRgb: 161, 200, 98;

$notFertilePlanFertilityColor: #c9dd84;
$notFertilePlanFertilityColorRgb: 201, 221, 132;

$useProtectionFertilityColor: #ff736f;
$useProtectionFertilityColorRgb: 255, 115, 111;

$pregnantFertilityColor: #63a4f8;
$pregnantFertilityColorRgb: 99, 164, 248;

$recoveryFertilityColor: #d294ef;
$recoveryFertilityColorRgb: 210, 148, 239;

$offlineFertilityColor: #ff9000;
$offlineFertilityColorRgb: 255, 144, 0;

$moreDataNeededFertilityColor: #e8d2c6;
$moreDataNeededFertilityColorRgb: 232, 210, 198;

$unlikelyFertilityColor: #ffb7b5;
$unlikelyFertilityColorRgb: 255, 183, 181;

$lowFertilityColor: #ff928e;
$lowFertilityColorRgb: 255, 146, 142;

$mediumFertilityColor: #ff736f;
$mediumFertilityColorRgb: 255, 115, 111;

$highFertilityColor: #f9584f;
$highFertilityColorRgb: 249, 88, 79;

$veryHighFertilityColor: #e7483f;
$veryHighFertilityColorRgb: 231, 72, 63;

$peakFertilityColor: #d83030;
$peakFertilityColorRgb: 216, 48, 48;

/* Trackers colors */

$moodTrackerColor: #ffdf5c;
$moodTrackerColorRgb: 255, 223, 92;

$painTrackerColor: #ff9a75;
$painTrackerColorRgb: 255, 154, 117;

$painTrackerColorContrast: #ac2e00;
$painTrackerColorContrastRgb: 172, 46, 0;

$skinTrackerColor: #ff928e;
$skinTrackerColorRgb: 255, 146, 142;

$skinTrackerColorContrast: #b12a25;
$skinTrackerColorContrastRgb: 177, 42, 37;

$sexTrackerColor: #fd90b4;
$sexTrackerColorRgb: 253, 144, 180;

$sexTrackerColorContrast: #a93059;
$sexTrackerColorContrastRgb: 169, 48, 89;

$trackerColor: #620d4e;
$trackerColorRgb: rgb(98, 13, 78);

$cervicalMucusTrackerColor: #f196e7;
$cervicalMucusTrackerColorRgb: 241, 150, 231;

$cervicalMucusTrackerColorContrast: #8f1f83;
$cervicalMucusTrackerColorContrastRgb: 143, 31, 131;

$bleedingTrackerColor: #d294ef;
$bleedingTrackerColorRgb: 210, 148, 239;

$bleedingTrackerColorContrast: #72035d;
$bleedingTrackerColorContrastRgb: 114, 3, 93;

$excludedTrackerColor: #ff736f;
$excludedTrackerColorRgb: 255, 115, 111;

$lhTestTrackerColor: #8dbeff;
$lhTestTrackerColorRgb: 141, 190, 255;

$lhTestTrackerColorContrast: #16427b;
$lhTestTrackerColorContrastRgb: 22, 66, 123;

$pregnancyTestTrackerColor: #63a4f8;
$pregnancyTestTrackerColorRgb: 99, 164, 248;

$sensitiveTrackerColor: #e2d4d4;
$sensitiveTrackerColorRgb: 226, 212, 212;

$sleepTrackerColor: #abb1d2;
$sleepTrackerColorRgb: 171, 177, 210;

$sleepTrackerColorContrast: #4d5478;
$sleepTrackerColorContrastRgb: 77, 84, 120;

$breastFeedingTrackerColor: #ffcdd3;
$breastFeedingTrackerColorRgb: 255, 205, 211;

$breastFeedingTrackerColorContrast: #8f343f;
$breastFeedingTrackerColorContrastRgb: 143, 52, 63;

$bottleFeedingTrackerColor: #72d6c4;
$bottleFeedingTrackerColorRgb: 114, 214, 196;

$bottleFeedingTrackerColorContrast: #005a46;
$bottleFeedingTrackerColorContrastRgb: 0, 90, 70;

$pumpingTrackerColor: #ffb800;
$pumpingTrackerColorRgb: 255, 184, 0;

$pumpingTrackerColorContrast: #915f04;
$pumpingTrackerColorContrastRgb: 145, 95, 4;

/* 3rd party colors */
$ouraColor: #2f4a73;
$ouraColorRgb: 47, 74, 115;
