@use 'fonts' as *;

ion-textarea {
  @include font-size-14;

  border: 1px solid var(--ion-border-color);
  border-radius: 5px;
}

.textarea-bottom {
  border-top: none !important;
  padding-bottom: 5px !important;
}
