@use 'fonts' as *;
@use 'mixins' as *;

ion-button {
  &:not(.in-toolbar, .button-clear) {
    // Primary color for buttons are the secondary theme color, because why not
    --ion-color-primary: var(--ion-color-secondary);
    --ion-color-primary-rgb: var(--ion-color-secondary-rgb);
    --ion-color-primary-contrast: var(--ion-color-secondary-contrast);
    --ion-color-primary-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
    --ion-color-primary-shade: var(--ion-color-secondary-shade);
    --ion-color-primary-tint: var(--ion-color-secondary-tint);
  }

  @include button-m;

  --box-shadow: 0;
  --border-width: 1px;

  height: 46px;
  position: relative;

  &.ion-color-gradient {
    font-weight: 400;

    &:hover {
      --background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(
          258.78deg,
          #ffb9f9 6.17%,
          #ffccfa 16.93%,
          #fff5f5 48.2%,
          #cce2ff 85.34%,
          #bad9ff 96.58%
        );
    }

    &:active {
      --background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.16)),
        linear-gradient(
          258.78deg,
          #ffb9f9 6.17%,
          #ffccfa 16.93%,
          #fff5f5 48.2%,
          #cce2ff 85.34%,
          #bad9ff 96.58%
        );
    }
  }

  &.button-round {
    --border-radius: 3em;
  }

  &.button-full,
  &.button-block {
    margin-left: 0;
    margin-right: 0;
    flex-grow: 1;
  }

  &.button-small {
    @include button-s;

    --padding-start: 1em;
    --padding-end: 1em;

    height: 32px;
  }

  &.button-disabled {
    * {
      pointer-events: none;
    }
  }

  &.button--alignEnd {
    align-self: flex-end;
  }

  &:focus {
    outline: none;
  }

  ion-toolbar &.button-has-icon-only {
    --overflow: visible;
  }

  &.button-block {
    height: auto;
    min-height: 46px;

    ion-label {
      white-space: normal !important;
      line-height: 1.4em;
      padding: 0.75em 0;
    }

    &[fill='outline'] {
      ion-label {
        padding: 0.688em 0;
      }
    }
  }

  ion-label {
    line-height: 1.2em;
  }

  &.button-solid,
  &.button-outline,
  &.button-clear {
    &.ion-focused {
      --background-focused-opacity: 0;

      outline: none;

      &::before {
        @include button-focus-outline;
      }
    }
  }

  &.button-solid:not(.ion-color-gradient) {
    &:hover {
      --background-hover-opacity: 0;
      --background: var(--ion-color-secondary-shade);
    }
  }

  &.button-outline {
    &:hover {
      --background-hover-opacity: 0.1;
    }
  }
}

.button--loading {
  opacity: 1 !important;
  pointer-events: none !important;

  * {
    pointer-events: none !important;
  }
}

.button--spinner {
  position: relative;

  ion-label {
    opacity: 0 !important;
  }

  ion-icon {
    opacity: 0 !important;
  }

  &::after {
    border: 2px solid rgba(var(--ion-color-contrast-rgb, var(--ion-color-medium-rgb)), 0.3);
    border-top-color: var(--ion-color-contrast, var(--ion-color-black));
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -12px 0 0 -12px;
    width: 24px;
    height: 24px;
    animation: spin 1s ease-in-out infinite;
    box-sizing: border-box;
    z-index: 999;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

ion-button[keyboard] {
  transition: transform 250ms ease-in-out;
}

ion-back-button {
  --icon-font-size: 1.5rem;

  &.ion-focused {
    &::before {
      @include button-focus-outline;
    }
  }
}

ion-fab-button {
  &:hover {
    &::part(native) {
      --background-hover-opacity: 0;
      background: var(--ion-color-secondary-shade);
    }
  }

  &.ion-focused {
    --background-focused-opacity: 0;

    outline: none;

    &::before {
      @include button-focus-outline;
    }
  }
}

// special cases for dark mode
[appearance='DARK'] {
  ion-button {
    &.button-outline {
      // outlined secondary color buttons are white instead of purple in darkmode
      --ion-color-secondary: var(--ion-color-white);
    }
  }
}

ion-segment-button {
  &.ion-focused {
    &::before {
      @include button-focus-outline;

      left: 0;
      width: calc(100% - 6px);
    }
  }
}
