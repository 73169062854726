@use 'fonts' as *;
@use 'mixins' as *;

@font-face {
  font-family: 'OpenSauceOne';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/OpenSauceOne-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'OpenSauceOne';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/OpenSauceOne-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'OpenSauceOne';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/OpenSauceOne-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'OpenSauceOne';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/OpenSauceOne-ExtraBold.otf') format('opentype');
}

@font-face {
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/DMMono-Regular.ttf') format('truetype');
}

html {
  --ion-font-family: 'OpenSauceOne', sans-serif;

  font-family: var(--ion-font-family);
}

p,
ol,
ul {
  @include body-m;

  margin: 0.5em 0 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1em 0 0.5em;
}

h1 {
  @include title-xl;

  margin: 0.5em 0 1em;
}

h2 {
  @include title-m;

  margin: 0.5em 0 1em;
}

h3,
h4,
h5,
h6 {
  @include title-s;
}

a {
  color: var(--ion-color-primary-shade);
  text-decoration: none;
  outline: none;
  position: relative;

  &:focus::before {
    @include block-focus-outline;
  }
}

li {
  margin: 0 0 0.25em;
}

li,
ol {
  text-align: left !important;
}

table,
th,
td {
  border: 1px solid var(--ion-border-color);
  text-align: left;
}

table {
  @include body-m;
  border-collapse: collapse;
}

th {
  color: var(--ion-text-color);
  padding: 8px;
}

td {
  color: var(--ion-text-color);
  padding: 8px;
}

small {
  @include body-xs;

  display: inline-block;
}

hr {
  background: linear-gradient(
    89.81deg,
    rgba(255, 205, 211, 0) 0%,
    #ffcdd3 50.03%,
    rgba(255, 205, 211, 0) 100%
  );
  opacity: 0.4;
  margin: 1em 0;
}
