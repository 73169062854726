// App Global CSS
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply globally. These styles are for
// the entire app and not just one component. Additionally, this file can be
// also used as an entry point to import other Sass files to be included in the
// output CSS.

/* Core CSS required for Ionic components to work properly */
@use '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';

// /* Optional CSS utils that can be commented out */
@use '@ionic/angular/css/padding.css';
// @use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
// @use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';

/***************************/
/** NC° styles start here **/
/***************************/

@use './typography' as NCTypography;
@use './other';

/* Themes */
@use './theme';

/* Ionic style */
@use './ionic';

/* Custom global style */
@use './custom';

/** Swiper use */
@use 'swiper/scss';
@use 'swiper/scss/navigation';
@use 'swiper/scss/pagination';
