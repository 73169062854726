@mixin button-focus-outline {
  --margin: -5px;

  border: var(--ion-color-accessibleBlue) solid 3px;
  border-radius: var(--border-radius);
  bottom: var(--margin);
  left: var(--margin);
  right: var(--margin);
  top: var(--margin);
  position: absolute;
  content: '';
  pointer-events: none;
  z-index: 2;
  transition: none;
  opacity: 1;
}

@mixin block-focus-outline {
  @include button-focus-outline;

  --margin: -3px;
  --size: 100%;

  height: var(--size);
  width: var(--size);
}

@mixin toggle-focus-outline {
  @include button-focus-outline;

  --height: 34px; // same height as inset on a rendered `ion-toggle`
  --width: calc(100% + 2px);

  height: var(--height);
  width: var(--width);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
