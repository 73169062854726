@use 'fonts' as *;

ion-title {
  @include title-m;

  padding: 0;
}

.header-md {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

ion-toolbar {
  // match styling of ion-back-button
  ion-button.button-has-icon-only,
  app-info-button {
    ion-header & {
      --border-radius: 50% !important;
      --overflow: hidden;
      --padding-start: 12px;
      --padding-end: 12px;

      color: var(--ion-text-color);
      margin-inline-start: 0;
      margin-inline-end: 0;
      width: 48px;
      height: 48px;
    }
  }

  app-info-button {
    padding: 2px;
  }

  ion-header & {
    height: 52px;
    box-sizing: content-box;
  }
}

.can-go-back {
  // Hide close button if page was opened with a horizontal slide animation
  &:not(.show-close-button) {
    ion-header ion-buttons[slot='start'] ion-back-button[icon='close'] {
      display: none;
    }
  }

  // Hide back arrow button if page was opened with a vertical slide animation
  // (Only if there are two or more buttons in 'start' slot to make sure it's always possible to go back)
  &.show-close-button {
    ion-header ion-buttons[slot='start'] {
      ion-back-button:not(:only-child, [icon='close']):first-of-type {
        display: none;
      }
    }
  }
}
