.ncFrame {
  app-big-circle-container {
    --max-width: 376px;
  }

  ion-content.graph {
    width: 100% !important;
  }

  .notes__input textarea {
    max-height: 420px !important;
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    left: auto;
    top: auto;
  }

  .modal--alert .modal-wrapper {
    height: auto;
  }

  .alert-md .alert-wrapper {
    min-width: 85%;
  }

  .img__wrapper,
  .guide__slide__img__wrapper {
    max-height: 260px !important;
  }

  .thumbSlides__slide {
    width: 120px !important;

    &--small {
      width: 66px !important;
    }
  }

  .blog__slide {
    width: 256px !important;
  }

  .blog__more {
    span {
      padding-top: 166px !important;
    }
  }

  .monthPage__weeks {
    // To fit 6-week month cards in the frame
    gap: 0 !important;
  }

  .monthPage__appTour {
    &--step3 {
      position: absolute !important;
      left: 14% !important;
      top: 222px !important;
    }
  }

  .monthPage__fab {
    right: 16px !important;
  }

  .addData__extraButton {
    width: auto !important;
    margin: 0 16px 16px !important;
  }
}
