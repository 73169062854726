@use 'fonts' as *;
@use 'mixins' as *;

.sc-ion-alert-md-h {
  @include body-s;

  --max-width: 340px;

  padding: 16px;
  padding-bottom: var(--keyboardHeight, 16px);
  transition: padding 400ms ease-in-out;

  .alert-title {
    @include title-m;
  }

  .alert-wrapper {
    box-shadow: none;
  }

  .alert-head {
    padding-inline-start: 32px;
    padding-inline-end: 32px;
    padding-bottom: 16px;
    padding-top: 48px;
  }

  .alert-sub-title {
    @include body-s;
  }

  .alert-message {
    @include body-m;

    padding: 0 32px 32px;
    white-space: pre-wrap;

    md {
      white-space: initial;

      p {
        white-space: pre-wrap;
      }
    }

    .ovulationIcon {
      opacity: 0.54;
    }

    h2:first-of-type {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .alert-radio-label,
  .alert-checkbox-label {
    @include body-m;
  }

  .alert-input-group {
    padding-inline-start: 32px;
    padding-inline-end: 32px;
    padding-top: 0;

    input {
      border: 1px solid var(--ion-border-color);
      height: 60px;
      padding: 12px;
      border-radius: 2px;
      margin: 0;

      &:focus {
        border: 1px solid var(--ion-border-color);
        margin: 0;
      }
    }

    input::placeholder {
      color: var(--ion-placeholder-color);
      opacity: 1;
    }
  }

  .alert-radio-group,
  .alert-checkbox-group {
    border-color: var(--ion-border-color);
    max-height: none;
  }

  &.cancel-alert {
    --ion-color-step-150: var(--ion-color-lightGray);
    --height: auto;

    .alert-head {
      padding: 24px 24px 16px;
    }

    .alert-radio-group.sc-ion-alert-md {
      max-height: none;
    }

    .alert-radio-icon.sc-ion-alert-md {
      align-self: flex-start;
      margin-top: 8px;
    }

    .alert-radio-label.sc-ion-alert-md {
      white-space: normal;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.alert-button-group {
  padding-inline-start: 16px;
  padding-inline-end: 16px;

  .alert--scrollable & {
    box-shadow: var(--nc-box-shadow);
  }

  .alert-button {
    @include font-size-14;

    --ion-color-primary: var(--ion-color-white);

    background: var(--ion-color-secondary);
    border-radius: 3em;
    padding: 9px 16px;
    text-transform: none;
    line-height: 0.875rem;

    &:hover {
      background: var(--ion-color-secondary-shade);
    }

    &.ion-focused {
      background: var(--ion-color-secondary);
      overflow: visible;

      &::before {
        @include button-focus-outline;

        border-radius: inherit;
      }
    }

    html[appearance='DARK'] & {
      --ion-color-primary: var(--ion-color-black);
    }

    &.alert-button-role-cancel {
      --ion-color-primary: var(--ion-color-secondary);

      background: transparent;
      border: var(--ion-color-secondary) solid 1px;

      &:hover {
        background: rgba(var(--ion-color-secondary-rgb), 0.07);
      }

      .ion-focused & {
        background: transparent;
      }

      html[appearance='DARK'] & {
        --ion-color-primary: var(--ion-color-white);

        border-color: var(--ion-color-white);
      }
    }
  }

  &[class*='sc-ion-alert-md'] {
    flex-wrap: wrap;
    gap: 8px;
  }

  .sc-ion-alert-md-h:not(.alert--scrollable) & {
    padding-bottom: 16px;
  }

  .sc-ion-alert-md-h.alert--attentionButtons & {
    padding: 24px 32px 32px;
  }
}

.alert--message {
  .alert-head {
    padding: 26px 0 0;
  }
}

.alert--full--screen,
.alert--message {
  .alert-message {
    max-height: 65vh;
  }
}

.alert--attentionButtons {
  .alert-button-group {
    gap: 20px;
  }

  button.alert-button {
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    border: none;
    border-radius: 23px;
    width: 100%;
    height: 46px;
    margin: 0;

    .alert-button-inner {
      justify-content: center;
    }

    &.button--warning {
      background-color: var(--ion-color-warning);
      color: var(--ion-color-warning-contrast);
    }

    &.button--outline {
      color: var(--ion-color-secondary);
      background-color: transparent;
      border: 1px solid var(--ion-color-secondary);
    }
  }
}
