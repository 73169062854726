@use 'fonts' as *;
@use 'mixins' as *;

ion-item-group {
  border-bottom: 24px solid var(--ion-tab-bar-background);
  padding: 10px 0;

  &:last-child {
    border: none;
  }
}

ion-list-header {
  @include title-s;

  align-items: flex-end;
}

ion-item-divider {
  @include subtitle-m;

  --color: var(--ion-text-color);
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;

  border: none;
  min-height: 24px;
  text-align: center;

  ion-label {
    margin: 0 auto;
  }
}

[slot='start'] {
  margin-right: 8px;
}

[slot='end'] {
  margin-left: 8px;
}

ion-item {
  @include body-m;

  &[detail='true'] {
    --detail-icon-opacity: 1;
    --inner-padding-end: 24px;
  }

  --color: var(--ion-text-color);
  --min-height: 50px;
  --ripple-color: var(--ion-color-medium);

  /* Optional CSS, this is added for the flashing that happens when toggling between themes */
  --transition: none;

  app-info-button[slot='end'] {
    margin-right: -10px;
    z-index: 2;
    align-self: flex-start;
    margin-top: 2px;
  }

  ion-icon:not([slot='icon-only']) {
    color: var(--ion-color-contrast);
  }

  .label-stacked.sc-ion-label-md-h {
    @include body-xs;

    transform: none;
  }

  ion-note[slot='end'] {
    max-width: 60%;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    align-self: center;
  }

  &.item-disabled {
    ion-icon {
      pointer-events: none;
    }
  }

  &.no-padding {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  &.ion-focused {
    --background-focused-opacity: 0;

    outline: none;
    overflow: visible;

    &::before {
      @include block-focus-outline;

      --margin: 0;
      --size: calc(100% - 6px);
    }
  }

  &:has(ion-input).ion-focused::before {
    background: transparent;
    width: var(--size) !important;
  }

  &:has(ion-input.has-focus) {
    --highlight-background: var(--ion-border-color);
    --highlight-height: 0.1px;
  }

  &:has(ion-input) {
    --border-color: var(--highlight-background, var(--ion-border-color));
    --border-width: 1px;
    --highlight-color-focused: var(--ion-color-text);
    --highlight-color-valid: var(--ion-border-color);
    --highlight-color-invalid: var(--ion-color-danger);
    --highlight-height: 1px;
    --inner-padding-end: 12px;
    --min-height: 60px;
    --padding-start: 12px;
    --placeholder-font-style: 14px;
    --inner-border-width: 0px;

    border-radius: 2px;
    align-items: center;
    cursor: pointer;

    [slot='end'] {
      margin-top: 10px;

      &.button-small {
        --padding-end: 6px;
        --padding-start: 6px;
      }
    }

    position: relative;

    &::before {
      background: var(--border-color);
      content: '';
      width: 0;
      transition: 0.2s ease-in-out;
      height: 1px;
      position: absolute;
      left: 50%;
      bottom: 1px;
      z-index: 2;
    }

    &.ion-invalid.ion-touched,
    &:has(ion-input.has-focus) {
      &:not(:has(progress[value]))::before {
        width: 100%;
        left: 0;
      }
    }

    &.ion-invalid.ion-touched {
      &:has(ion-input:not(.has-focus)) {
        --highlight-background: var(--highlight-color-invalid);
      }
    }

    &.ion-invalid.ion-untouched {
      --border-color: var(--highlight-color-valid);
    }

    ion-input {
      .label-text {
        color: var(--ion-placeholder-color) !important;
        white-space: nowrap;
        overflow: hidden;

        &[required] {
          &::after {
            content: ' *';
          }
        }
      }

      &.input-label-placement-floating {
        min-height: 4rem !important;

        label .label-text-wrapper.sc-ion-input-md {
          transform: translateY(96%);
          margin-top: -2px;
        }
      }

      label .label-text-wrapper.sc-ion-input-md {
        .label-text {
          @include body-s;
        }
      }

      &.label-floating .label-text-wrapper {
        transform: translateY(50%) !important;
      }
    }
  }

  &.input__withIcons {
    &:has(ion-input) {
      &.ion-valid.item-has-value,
      &.ion-invalid {
        &::after {
          content: '';
          width: 24px;
          height: 24px;
          position: absolute;
          right: 10px;
          top: 50%;
          margin-top: -12px;
          z-index: 99999;
        }
      }

      &.ion-valid::after {
        filter: var(--pseudo-element-filter-primary);
        background: url('/assets/img/icons/check.svg');
      }

      &.ion-invalid:not(.ion-untouched)::after {
        filter: var(--pseudo-element-filter-danger);
        background: url('/assets/img/icons/warning.svg');
        transform: scale(70%);
      }
    }
  }

  &.input__withSelect {
    &:has(ion-input) {
      &::after {
        filter: var(--pseudo-element-filter);
        content: ' ';
        background: url('/assets/img/icons/arrow-down.svg');
        background-size: cover;
        width: 16px !important;
        height: 16px !important;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -8px !important;
        z-index: 1;
      }
    }
  }
}
