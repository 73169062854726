@use 'fonts' as *;

.sc-ion-action-sheet-md-h {
  --color: var(--ion-text-color);

  ion-icon[aria-label='stop'] {
    background-image: url('/assets/img/icons/clear.svg');
  }

  ion-icon.action-sheet-icon {
    height: 24px;
    width: 24px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    flex-shrink: 0;
    margin-inline-end: 16px;
  }

  .action-sheet-button {
    @include body-m;
  }
}

.action-sheet-group-cancel {
  border-top: 1px solid var(--ion-border-color);
}
