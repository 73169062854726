@use 'mixins' as *;

ion-radio {
  --color: var(--ion-text-color);
  --border-width: 2px;

  &[slot] * {
    white-space: normal !important;
    text-align: left;
  }

  &::part(container) {
    width: 20px;
    height: 20px;
  }

  &.ion-focused {
    &::before {
      @include block-focus-outline;

      --border-radius: 5px;
      --size: calc(100% - 6px);
      --margin: 0;
    }
  }
}
