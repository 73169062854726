@use 'fonts' as *;

// The home for all the random styles, because we will always have them

// We don't want to display the recaptcha badge
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}

.platform-core ::-webkit-scrollbar,
.platform-core *::-webkit-scrollbar {
  display: none;
}

#lagradar {
  z-index: 999999;
  position: fixed;
  bottom: 50px;
  right: 0;
  width: 200px;
  height: 200px;
  pointer-events: none;
  user-select: none;
  // border: 1px solid red;
}

#loading0 {
  background: var(--ion-color-darkPurple);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  transition: opacity 500ms linear;
  -webkit-transition: opacity 300ms linear;
}

#loading0.opacity0 {
  opacity: 0;
  pointer-events: 0;
}

#loadingLogo {
  fill: var(--ion-color-medium);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 300px;
  height: 300px;
  padding-left: 40px;
  margin: auto;
  animation: pulsatingLogo 3s infinite;
  animation-timing-function: ease;
}

@keyframes pulsatingLogo {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
    transform: scale(0.9);
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

app-box {
  ion-item {
    --background: transparent;
  }

  p {
    margin: 0 0 0.2em;
  }
}

// some message styling is here since the elements are rendered by tmd and youtube and are not encapsulated
page-glossary-item {
  img {
    max-width: 100%;
    border-radius: 4px;
  }
}

.message__markdown {
  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-block-end: 8px;
  }

  ol {
    margin-block-end: 8px;
  }

  h1,
  h2,
  h3 {
    margin-bottom: 8px;
  }

  h1 {
    @include title-m;

    margin: 0 0 16px;
    padding-right: 1em;
  }

  h2,
  h3 {
    @include title-s;
  }

  p,
  div {
    margin-bottom: 0.5em;
    white-space: pre-wrap;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .ncEmoji {
    height: 16px;
    width: 16px;
    margin: 0;
  }
}

.messageModal__content {
  h1 {
    @include title-l;
  }
}

.infoModal__html {
  h2 {
    @include title-l;
    margin-top: 24px;
  }
}

.message__video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.guide__slide__body {
  ul,
  ol {
    text-align: left;
  }
}

.keyboard-open {
  ion-tab-bar {
    display: none !important;
  }
}

ion-content.keyboard-open {
  --padding-bottom: var(--keyboardHeight);
}

.orientation--white {
  opacity: 0 !important;
}

.auth__terms {
  p {
    @include body-s;
  }
}

.textContainer__message__alertBox {
  p {
    @include body-s;
    margin: 0;
  }
}

.confettiCanvas {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
  pointer-events: none;
}

@media (pointer: coarse) {
  * {
    --ion-color-tint: var(--ion-color-base) !important;
    --ion-item-background-hover: var(--ion-color-base) !important;
    --background-hover: var(--background) !important;
    --background-focused: var(--background) !important;
  }
}

@media only screen and (orientation: landscape) {
  app-root:not(.ncFrame) {
    page-home,
    page-partner {
      > * {
        display: none !important;
      }

      &:before {
        background-color: var(--ion-tab-bar-background);
        opacity: 0.35;
        content: '';
        position: absolute;
        top: 90px;
        left: max(var(--ion-safe-area-top), var(--ion-safe-area-left));
        right: calc(48px + max(var(--ion-safe-area-top, --ion-safe-area-right)));
        bottom: 96px;
      }
    }
  }

  html:not(.plt-mobileweb) {
    app-root:not(.ncFrame) {
      page-add-data {
        display: none !important;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  app-root:not(.ncFrame) {
    page-graph {
      margin: 0;

      > * {
        display: none !important;
      }

      &:before {
        background-color: var(--ion-toolbar-background-home);
        padding-top: var(--ion-safe-area-top);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 86px;
      }

      &:after {
        background-color: var(--ion-tab-bar-background);
        padding-top: var(--ion-safe-area-bottom);
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50px;
      }
    }
  }
}

.referPage__markdown {
  ul {
    padding: 0;
    list-style: none;

    li {
      padding-left: 86px;
      padding-bottom: 40px;
      margin-bottom: -40px;
      background-repeat: no-repeat;
    }

    li:first-child {
      background-image: url('/assets/img/refer/refer-1.svg');
    }
    li:nth-child(2) {
      background-image: url('/assets/img/refer/refer-2.svg');
    }
    li:nth-child(3) {
      background-image: url('/assets/img/refer/refer-3.svg');
      background-position-y: -6px;
    }
  }

  &.bt {
    li:nth-child(2) {
      background-image: url('/assets/img/refer/refer-2-bt.svg');
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  height: 100%;

  // to set proper height for slides on the Insights page
  // as swiper does not support autoHeight for vertical slides yet
  &:not(.swiper-slide-active) > .insightsPage__summaryContainer {
    display: none;
  }
}

.swiper-pagination {
  --bullet-background: var(--ion-color-logo);
  --bullet-background-active: var(--ion-color-logo);
}

.swiper-pagination-bullet {
  margin-right: 4px;
}

.swiper-pagination-bullet-active {
  --swiper-theme-color: var(--ion-color-logo);
}

/*
Improves accessibility of <s> tag (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/s#accessibility_concerns)
*/
s::before,
s::after {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

s::before {
  content: ' [start of stricken text] ';
}

s::after {
  content: ' [end of stricken text] ';
}

.errorSnippet {
  line-height: 1.1;
  font-size: 0.8rem;
}

// Fix of the ionic bug where caret matches the valid form color - green
ion-input input {
  caret-color: auto !important;
}

app-checkin-cards {
  .swiper-slide-shadow {
    border-radius: 5px;
    background: var(--checkin-bg-color) !important;
    box-shadow: 0 2px 4px rgba(var(--ion-color-black-rgb), 0.05);
  }
}

// We don't want to display the legacy google sign in button. We need to keep it in the DOM for google auth to work.
.legacy-g-signin__button {
  position: absolute;
  // If set to 0 the button does not respond to clicks
  opacity: 0.0001;
  overflow: hidden;
}

.marked-underline {
  mark {
    background-color: inherit;
    color: inherit;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      right: 0;
      height: 10px;
      width: 100%;
      background: url('/assets/img/marker-underline.svg') no-repeat;
      background-size: contain;
    }
  }
}

/**
  * Used by the "Inspector" red dot functionality to highlight elements
  */
*[__inspector_translate_key],
*[__inspector_img_url] {
  outline: 1px solid red !important;
}
