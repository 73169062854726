@use 'colors' as *;

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// we need RGB values because -> https://ionicframework.com/docs/theming/advanced#the-alpha-problem

/**
* Ionic CSS Variables
* --------------------------------------------------
* ionic theme colors, you can generate shade and tint here: https://ionicframework.com/docs/theming/color-generator
* remember to add new colors to $customColors
*/
[appearance='LIGHT'] {
  /**
  * Ionic colors
  */
  /** Primary **/
  @include color(
    'primary',
    $primaryColorLight,
    $primaryColorLightRgb,
    $whiteColor,
    $whiteColorRgb,
    $primaryColorLightShade,
    #3c82dd
  );

  /** Accessible blue **/
  // same as primary, but necessary if we want to force a blue button
  @include color(
    'accessibleBlue',
    $primaryColorLight,
    $primaryColorLightRgb,
    $whiteColor,
    $whiteColorRgb,
    $primaryColorLightShade,
    #3c82dd
  );

  /** Secondary **/
  @include color(
    'secondary',
    $secondaryColorLight,
    $secondaryColorLightRgb,
    $whiteColor,
    $whiteColorRgb,
    $secondaryColorLightShade,
    $secondaryColorLightTint
  );

  /** Tertiary **/
  @include color(
    'tertiary',
    $placeholderGrayColor,
    $placeholderGrayColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #6f6f6f,
    #4f4f4f
  );

  /** Danger **/
  @include color(
    'danger',
    $redAlertColorLight,
    $redAlertColorLightRgb,
    $whiteColor,
    $whiteColorRgb,
    #d83030,
    #dc4545
  );

  /** Text */
  @include color(
    'text',
    $blackColor,
    $blackColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #2c0c38,
    #472653
  );

  /** Prediction **/
  @include color(
    'prediction',
    $predictionColor,
    $predictionColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #676767,
    #838383
  );

  /** Layer */
  @include color(
    'layer',
    rgba(var(--ion-color-black-rgb), 0.08),
    rgba(var(--ion-color-black-rgb), 0.08),
    $blackColor,
    $blackColorRgb,
    rgba(var(--ion-color-black-rgb), 0.16),
    #f2ecec
  );

  /** Surface secondary */
  @include color(
    'surfaceSecondary',
    $keyNeutralColor,
    $keyNeutralColorRgb,
    $blackColor,
    $blackColorRgb,
    rgba(var(--ion-color-black-rgb), 0.16),
    rgba(var(--ion-color-white-rgb), 0.16)
  );

  /** Logo */
  @include color(
    'logo',
    $secondaryColorLight,
    $secondaryColorLightRgb,
    $whiteColor,
    $whiteColorRgb,
    #510d3e,
    #6c2759
  );

  /** Snackbar button */
  @include color(
    'snackbarButton',
    $accentColorShade,
    $accentColorShadeRgb,
    $blackColor,
    $blackColorRgb,
    #e0807d,
    $accentColorTint
  );

  $customColors: (
    'accessibleBlue',
    'prediction',
    'text',
    'layer',
    'surfaceSecondary',
    'logo',
    'snackbarButton'
  );

  @each $color in $customColors {
    .ion-color-#{$color} {
      --ion-color-base: var(--ion-color-#{$color}); // !important;
      --ion-color-base-rgb: var(--ion-color-#{$color}-rgb); // !important;
      --ion-color-contrast: var(--ion-color-#{$color}-contrast); // !important;
      --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb); // !important;
      --ion-color-shade: var(--ion-color-#{$color}-shade); // !important;
      --ion-color-tint: var(--ion-color-#{$color}-tint); // !important;
      //--background: var(--ion-color-#{$color}-background) !important;
      //--background-activated: var(--ion-color-#{$color}-background-activated) !important;
    }
  }

  // Shared Variables
  // --------------------------------------------------
  // To customize the look and feel of this app, you can override
  // the CSS variables found in Ionic's source files.
  // To view all the possible Ionic variables, see:
  // https://ionicframework.com/docs/theming/themes#application-colors

  /** Ionic variables */
  --ion-background-color: var(--ion-color-white); // Background color of the entire app
  --ion-background-color-rgb: var(
    --ion-color-white-rgb
  ); // Background color of the entire app, rgb format
  --ion-text-color: var(--ion-color-black); // Text color of the entire app
  --ion-text-color-rgb: var(--ion-color-black-rgb); // Text color of the entire app, rgb format
  --ion-backdrop-color: var(--ion-color-darkPurple); // Color of the Backdrop component
  --ion-backdrop-opacity: 0.25; // Opacity of the Backdrop component
  --ion-overlay-background-color: var(--ion-color-lightGray); // Background color of the overlays
  --ion-border-color: var(--ion-color-gray); // Border color
  --ion-box-shadow-color: var(--ion-color-black); // Box shadow color
  --ion-tab-bar-background: var(--ion-color-lightGray); // Background of the Tab Bar
  --ion-tab-bar-background-focused: var(--ion-color-lightGray); // Background of the focused Tab Bar
  --ion-tab-bar-border-color: var(--ion-color-medium); // Border color of the Tab Bar
  --ion-tab-bar-color: var(--ion-color-black); // Color of the Tab Bar
  --ion-tab-bar-color-selected: var(--ion-color-white); // Color of the selected Tab Button
  --ion-toolbar-background: var(--ion-color-white); // Background of the Toolbar
  --ion-toolbar-border-color: var(--ion-color-black); // Border color of the Toolbar
  --ion-toolbar-color: var(--ion-color-black); // Color of the components in the Toolbar
  --ion-toolbar-segment-color: var(--ion-text-color); // Color of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-color-checked: var(
    --ion-text-color
  ); // Color of the checked Segment Buttons in the Toolbar
  --ion-toolbar-segment-background: var(
    ion-color-white
  ); // Background of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-background-checked: transparent; // Background of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-indicator-color: var(
    ion-color-white
  ); // Color of the Segment Button indicator in the Toolbar
  --ion-item-background: var(--ion-color-white); // Background of the Item
  --ion-item-border-color: var(--ion-color-lightGray); // Border color of the Item
  --ion-item-color: var(--ion-color-black); // Color of the components in the Item
  --ion-placeholder-color: #{$placeholderGrayColor}; // Color of the placeholder in Inputs

  /** Custom variables */

  --ion-toolbar-background-home: var(
    --ion-color-lightGray
  ); // Background of the Toolbar at the Home pages

  --box-color: var(--ion-color-white);
  --box-color-rgb: var(--ion-color-white-rgb);
  --box-color-glossary: var(--ion-color-lightGray);
  --drawer-background: #{$secondaryColorLight};
  --intro-card-background: var(--ion-color-medium);
  --nc-box-shadow: 0 0 10px rgba(var(--ion-color-black-rgb), 0.15);
  --no-fertility-status-color: var(--ion-color-black);
  --pseudo-element-filter: invert(50%);
  --pseudo-element-filter-primary: invert(42%) sepia(66%) saturate(4003%) hue-rotate(199deg)
    brightness(91%) contrast(86%);
  --pseudo-element-filter-danger: invert(19%) sepia(51%) saturate(7491%) hue-rotate(353deg)
    brightness(94%) contrast(78%);
  --light-gray-background: var(--ion-color-lightGray);
  --checkout-button-accent: var(--ion-color-pink);
  --discount-tag-color: #{$useProtectionFertilityColor};

  --fert-color-Ghost: #{$keyNeutralColor};
  --prediction-circle-color: var(--fert-color-Ghost);

  --snackbar-withImg-background: var(--ion-color-secondary);
  --snackbar-withImg-text: var(--ion-color-white);
  --snackbar-withImg-button: var(--ion-color-pink);

  --tracker-color: #{$trackerColor};
  --tracker-intensity-border-color: var(--box-color);

  --key-neutral-rgb: #{$keyNeutralColorRgb};

  --checkin-bg-color: var(--box-color);
  --checkin-option-bg-color: #{$keyNeutralColorRgb};
  --checkin-mood-icon: #{$secondaryColorLight};
  --checkin-stat-bg-color: rgba(#{$recoveryFertilityColorRgb}, 0.16);
  --checkin-stat-icon-color: var(--ion-color-white);
  --checkin-tracker-bg-color: #{$keyNeutralColor};
  --checkin-tracker-selected-bg-color: var(--ion-color-white);

  --cycle-line-color: #{$keyNeutralColor};
  --pregnancy-cycle-line-color: rgba(var(--ion-color-lightBlue-rgb), 0.8);

  // Background-color on range-slider
  --range-slider-color: #{$sensitiveTrackerColor};

  /**
  * Stepped colors
  * ------------------------------
  * https://ionicframework.com/docs/v5/theming/themes#stepped-colors
  */
  --ion-color-step-50: var(--ion-color-white);
  --ion-color-step-100: var(--ion-color-white);
  --ion-color-step-150: var(--ion-color-white);
  --ion-color-step-200: var(--ion-color-white);
  --ion-color-step-250: var(--ion-color-white);
  --ion-color-step-300: var(--ion-color-white);
  --ion-color-step-350: var(--ion-color-white);
  --ion-color-step-400: var(--ion-color-white);
  --ion-color-step-450: var(--ion-color-white);
  --ion-color-step-500: var(--ion-color-black);
  --ion-color-step-550: var(--ion-color-black);
  --ion-color-step-600: var(--ion-color-black);
  --ion-color-step-650: var(--ion-color-black);
  --ion-color-step-700: var(--ion-color-black);
  --ion-color-step-750: var(--ion-color-black);
  --ion-color-step-800: var(--ion-color-black);
  --ion-color-step-850: var(--ion-color-black);
  --ion-color-step-900: var(--ion-color-black);
  --ion-color-step-950: var(--ion-color-black);
}
