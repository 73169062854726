@use 'fonts' as *;

ion-icon {
  @include font-size-24;
  --color: var(--ion-text-color);

  display: inline-block;
  position: relative;
  pointer-events: auto;

  &.icon-extraSmall {
    width: 10px;
    height: 10px;
  }

  &.icon-small {
    width: 18px;
    height: 18px;
  }

  &.icon-large {
    width: 32px;
    height: 32px;
  }
}
