// Good to know about ionic footer:
// For the transition animation to be nice,
// the first child element(s) needs to be static and cannot be dynamically added with *ngIf

ion-footer {
  padding: 0 16px 16px;
  background-color: var(--ion-background-color);

  // if the footer is a direct child in an ion-page
  .ion-page > & {
    padding-bottom: max(var(--ion-safe-area-bottom, 0), 24px);

    ion-button {
      margin: 0;

      &:not(:last-child, .in-toolbar),
      :not(ion-modal.modal--alert) & {
        margin-bottom: 16px;
      }
    }
  }

  ion-modal.modal--alert & {
    --ion-safe-area-bottom: 0;
  }
}

// remove material design style shadow top
.footer-md {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.footer--shadowTop {
  box-shadow: 0 -5px 20px rgba(var(--ion-color-black-rgb), 0.1);
}
