.ghost--loading {
  --today-fertility-color: var(--fert-color-Ghost, var(--ion-color-medium));

  .ghost {
    animation: pulsating 2s infinite;
    animation-timing-function: ease;

    .fertilityCircle__button,
    .fertilityCircle__button--prediction {
      --fertility-color: var(--fert-color-Ghost, var(--ion-color-medium));
    }

    .fertilityCircle__icon {
      opacity: 0;
    }
  }

  page-graph ion-tabs {
    filter: grayscale(1);
  }
}

@keyframes pulsating {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.7;
  }
}
