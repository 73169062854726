.ncEmoji {
  vertical-align: sub;

  &.clockIcon,
  &.ovulationIcon {
    opacity: 0.25;
  }

  &.warning-circleIcon {
    color: var(--ion-color-secondary);
  }

  &.aw-full-scheduleIcon {
    color: var(--ion-color-prediction);
  }
}

.alert-title {
  .ncEmoji {
    height: 24px;
    width: 24px;
  }
}

.guide__slide__body {
  .warning-customIcon {
    color: var(--ion-color-secondary);
  }
}

.messageModal__text {
  .ncEmoji {
    height: 16px;
    width: 16px;
    margin: 0;
  }
}
