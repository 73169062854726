@use 'fonts' as *;

ion-badge {
  @include font-size-12;

  font-weight: 800;
  line-height: 1;
  min-width: 16px;
  min-height: 16px;
  border-radius: 9px;
  top: 4px;
  padding: 2px;
}
