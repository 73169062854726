@use 'colors' as *;

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// we need RGB values because -> https://ionicframework.com/docs/theming/advanced#the-alpha-problem

/**
* Ionic CSS Variables
* --------------------------------------------------
* ionic theme colors, you can generate shade and tint here: https://ionicframework.com/docs/theming/color-generator
* remember to add new colors to $customColors
*/
[appearance='DARK'] {
  /**
  * Ionic colors
  */
  /** Primary **/
  @include color(
    'primary',
    $primaryColorDark,
    $primaryColorDarkRgb,
    $backgroundColorDark,
    $backgroundColorDarkRgb,
    $primaryColorDarkShade,
    #8cbeff
  );

  /** Accessible blue **/
  // same as primary, but necessary if we want to force a blue button
  @include color(
    'accessibleBlue',
    $primaryColorDark,
    $primaryColorDarkRgb,
    $backgroundColorDark,
    $backgroundColorDarkRgb,
    $primaryColorDarkShade,
    #8cbeff
  );

  /** Secondary **/
  @include color(
    'secondary',
    $secondaryColorDark,
    $secondaryColorDarkRgb,
    $blackColor,
    $blackColorRgb,
    $secondaryColorDarkShade,
    $secondaryColorDarkTint
  );

  /** Tertiary **/
  @include color(
    'tertiary',
    $support2NeutralColor,
    $support2NeutralColorRgb,
    $secondaryColorLightShade,
    $secondaryColorLightShadeRgb,
    #d2c4c4,
    #f2e4e4
  );

  /** Danger **/
  @include color(
    'danger',
    $redAlertColorDark,
    $redAlertColorDarkRgb,
    $whiteColor,
    $whiteColorRgb,
    #d83030,
    #dc4545
  );

  /** Text */
  @include color(
    'text',
    $support2NeutralColor,
    $support2NeutralColorRgb,
    $secondaryColorLightShade,
    $secondaryColorLightShadeRgb,
    #c7bbbb,
    #e5d8d8
  );

  /** Prediction **/
  @include color(
    'prediction',
    $support2NeutralColor,
    $support2NeutralColorRgb,
    $blackColor,
    $blackColorRgb,
    #d8d6d6,
    #f6f4f4
  );

  /** Layer */
  @include color(
    'layer',
    rgba(var(--ion-color-white-rgb), 0.08),
    rgba(var(--ion-color-white-rgb), 0.08),
    $support2NeutralColor,
    $support2NeutralColorRgb,
    rgba(var(--ion-color-white-rgb), 0.16),
    #f2ecec
  );

  /** Surface secondary */
  @include color(
    'surfaceSecondary',
    rgba(var(--key-neutral-rgb), 0.16),
    rgba(var(--key-neutral-rgb), 0.16),
    $support2NeutralColor,
    $support2NeutralColorRgb,
    rgba(var(--ion-color-black-rgb), 0.16),
    rgba(var(--ion-color-white-rgb), 0.16)
  );

  /** Logo */
  @include color(
    'logo',
    $support2NeutralColor,
    $support2NeutralColorRgb,
    $blackColor,
    $blackColorRgb,
    #c7bbbb,
    #e5d8d8
  );

  /** Snackbar button */
  @include color(
    'snackbarButton',
    $blackColor,
    $blackColor,
    $blackColor,
    $blackColor,
    #e0807d,
    $accentColorTint
  );

  $customColors: (
    'accessibleBlue',
    'prediction',
    'text',
    'layer',
    'surfaceSecondary',
    'logo',
    'snackbarButton'
  );

  @each $color in $customColors {
    .ion-color-#{$color} {
      --ion-color-base: var(--ion-color-#{$color}); // !important;
      --ion-color-base-rgb: var(--ion-color-#{$color}-rgb); // !important;
      --ion-color-contrast: var(--ion-color-#{$color}-contrast); // !important;
      --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb); // !important;
      --ion-color-shade: var(--ion-color-#{$color}-shade); // !important;
      --ion-color-tint: var(--ion-color-#{$color}-tint); // !important;
      //--background: var(--ion-color-#{$color}-background) !important;
      //--background-activated: var(--ion-color-#{$color}-background-activated) !important;
    }
  }

  // Shared Variables
  // --------------------------------------------------
  // To customize the look and feel of this app, you can override
  // the CSS variables found in Ionic's source files.
  // To view all the possible Ionic variables, see:
  // https://ionicframework.com/docs/theming/themes#application-colors

  /** Ionic variables */
  --ion-background-color: #{$backgroundColorDark}; // Background color of the entire app
  --ion-background-color-rgb: #{$backgroundColorDarkRgb}; // Background color of the entire app, rgb format
  --ion-text-color: var(--ion-color-medium); // Text color of the entire app
  --ion-text-color-rgb: var(--ion-color-medium-rgb); // Text color of the entire app, rgb format
  --ion-backdrop-color: var(--ion-color-gray); // Color of the Backdrop component
  --ion-backdrop-opacity: 0.25; // Opacity of the Backdrop component
  --ion-overlay-background-color: #{$backgroundColorDark}; // Background color of the overlays
  --ion-border-color: var(--ion-color-gray); // Border color
  --ion-box-shadow-color: var(--ion-color-black); // Box shadow color
  --ion-tab-bar-background: #{$toolbarBackgroundColorDark}; // Background of the Tab Bar
  --ion-tab-bar-background-focused: var(--ion-color-white); // Background of the focused Tab Bar
  --ion-tab-bar-border-color: var(--ion-color-medium); // Border color of the Tab Bar
  --ion-tab-bar-color: var(--ion-color-white); // Color of the Tab Bar
  --ion-tab-bar-color-selected: var(--ion-color-black); // Color of the selected Tab Button
  --ion-toolbar-background: #{$backgroundColorDark}; // Background of the Toolbar
  --ion-toolbar-border-color: var(--ion-color-black); // Border color of the Toolbar
  --ion-toolbar-color: var(--ion-color-medium); // Color of the components in the Toolbar
  --ion-toolbar-segment-color: var(--ion-text-color); // Color of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-color-checked: var(
    --ion-text-color
  ); // Color of the checked Segment Buttons in the Toolbar
  --ion-toolbar-segment-background: var(
    --ion-color-layer
  ); // Background of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-background-checked: transparent; // Background of the Segment Buttons in the Toolbar
  --ion-toolbar-segment-indicator-color: var(
    --ion-color-layer
  ); // Color of the Segment Button indicator in the Toolbar
  --ion-item-background: #{$backgroundColorDark}; // Background of the Item
  --ion-item-border-color: #{$toolbarBackgroundColorDark}; // Border color of the Item
  --ion-item-color: var(--ion-color-lightGray); // Color of the components in the Item
  --ion-placeholder-color: var(--ion-color-lightGray); // Color of the placeholder in Inputs

  /** Custom variables */

  --ion-toolbar-background-home: var(
    --ion-background-color
  ); // Background of the Toolbar at the Home pages

  --box-color: rgba(var(--ion-color-white-rgb), 0.08);
  --box-color-rgb:
    66, 26, 58; // using the absolute color here to make it possible to add transparency to the already transparent color
  --box-color-glossary: rgba(var(--ion-color-white-rgb), 0.08);
  --drawer-background: #{$secondaryColorLightShade};
  --intro-card-background: #{$toolbarBackgroundColorDark};
  --nc-box-shadow: 0 0 10px rgba(var(--ion-color-black-rgb), 0.15);
  --no-fertility-status-color: #{$support2NeutralColor};
  --pseudo-element-filter: invert(50%);
  --pseudo-element-filter-primary: invert(67%) sepia(6%) saturate(4081%) hue-rotate(183deg)
    brightness(102%) contrast(104%);
  --pseudo-element-filter-danger: invert(19%) sepia(51%) saturate(7491%) hue-rotate(353deg)
    brightness(94%) contrast(78%);
  --light-gray-background: rgba(#{$keyNeutralColorRgb}, 0.15);
  --checkout-button-accent: rgba(#{$keyNeutralColorRgb});
  --discount-tag-color: #{$useProtectionFertilityColor};

  --fert-color-Ghost: rgba(#{$keyNeutralColorRgb}, 0.45);
  --prediction-circle-color: var(--fert-color-Ghost);

  --snackbar-withImg-background: #{$keyNeutralColor};
  --snackbar-withImg-text: var(--ion-color-black);
  --snackbar-withImg-button: var(--ion-color-purple);

  --tracker-color: #{$trackerColor};
  --tracker-intensity-border-color: #{$boxBackgroundColorDark};

  --key-neutral-rgb: #{$keyNeutralColorRgb};

  --checkin-bg-color: #{$boxBackgroundColorDark};
  --checkin-option-bg-color: rgba(var(--box-color-rgb), 0.84);
  --checkin-mood-icon: var(--ion-color-text);
  --checkin-stat-bg-color: rgba(#{$recoveryFertilityColorRgb}, 0.16);
  --checkin-stat-icon-color: rgba(var(--ion-color-white-rgb), 0.08);
  --checkin-tracker-bg-color: rgba(#{$keyNeutralColorRgb}, 0.16);
  --checkin-tracker-selected-bg-color: var(--ion-color-black);

  --cycle-line-color: rgba(#{$keyNeutralColorRgb}, 0.16);
  --pregnancy-cycle-line-color: rgba(#{$primaryColorDarkRgb}, 0.15);

  // Background-color on range-slider
  --range-slider-color: rgba(#{$sensitiveTrackerColorRgb}, 0.24);

  /**
  * Stepped colors
  * ------------------------------
  * https://ionicframework.com/docs/v5/theming/themes#stepped-colors
  */
  --ion-color-step-50: var(--ion-color-black);
  --ion-color-step-100: var(--ion-color-black);
  --ion-color-step-150: var(--ion-color-black);
  --ion-color-step-200: var(--ion-color-black);
  --ion-color-step-250: var(--ion-color-black);
  --ion-color-step-300: var(--ion-color-black);
  --ion-color-step-350: var(--ion-color-black);
  --ion-color-step-400: var(--ion-color-black);
  --ion-color-step-450: var(--ion-color-black);
  --ion-color-step-500: var(--ion-color-white);
  --ion-color-step-550: var(--ion-color-white);
  --ion-color-step-600: var(--ion-color-white);
  --ion-color-step-650: var(--ion-color-white);
  --ion-color-step-700: var(--ion-color-white);
  --ion-color-step-750: var(--ion-color-white);
  --ion-color-step-800: var(--ion-color-white);
  --ion-color-step-850: var(--ion-color-white);
  --ion-color-step-900: var(--ion-color-white);
  --ion-color-step-950: var(--ion-color-white);
}
