ion-loading.sc-ion-loading-md-h.nc-logo-loader {
  --background: var(--ion-color-darkPurple);
  --min-width: 86px;
  --min-height: 86px;

  .loading-wrapper {
    border-radius: 5px;
    position: relative;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      width: 65px;
      height: 33px;
    }

    &::before {
      background: url('/assets/img/loader-nc.svg') no-repeat center;
    }

    &::after {
      background: url('/assets/img/loader-dot.svg') no-repeat center;
      animation: dotAnimation 0.6s cubic-bezier(0.5, 0.05, 1, 0.5) infinite alternate;
    }
  }
}

ion-loading.sc-ion-loading-md-h {
  --background: var(--ion-color-darkPurple);
  --spinner-color: var(--ion-color-white);
  --min-width: 100px;
  --min-height: 100px;
  --max-width: 200px;
  color: var(--ion-color-white);

  .loading-wrapper {
    flex-direction: column;
    padding: 32px 24px;
    border-radius: 8px;

    .loading-content {
      margin: 16px 0 0;
      -webkit-margin-start: 0 !important;
      margin-inline-start: 0 !important;
      text-align: center;
    }

    ion-spinner {
      margin: 16px 0;
      width: 50px;
      height: 50px;
    }
  }
}

@keyframes dotAnimation {
  from {
    transform: translate3d(0, -8px, 0) scaleY(1);
  }
  to {
    transform: translate3d(0, 0, 0) scaleY(0.5);
  }
}
