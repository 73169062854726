@use './themes/colors' as *;
@forward './themes/light';
@forward './themes/dark';

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// we need RGB values because -> https://ionicframework.com/docs/theming/advanced#the-alpha-problem
// last two colors in the mixing are "shade" and "tint" colors generated by Ionic Color Generator

/**
* Custom colors
*/
:root {
  /** Success **/
  @include color(
    'success',
    $greenAlertColor,
    $greenAlertColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #77a131,
    #93be4c
  );

  /** Warning **/
  @include color(
    'warning',
    $warningAlertColor,
    $warningAlertColorRgb,
    $blackColor,
    $blackColorRgb,
    #e07f00,
    #ff9b1a
  );

  /** Medium **/
  @include color(
    'medium',
    $support2NeutralColor,
    $support2NeutralColorRgb,
    $blackColor,
    $blackColorRgb,
    #c7bbbb,
    #e5d8d8 /* FIXME used in SensitiveTrackers modal!*/
  );

  /** White **/
  @include color(
    'white',
    $whiteColor,
    $whiteColorRgb,
    $blackColor,
    $blackColorRgb,
    #e0e0e0,
    $whiteColor
  );

  /** Black **/
  @include color(
    'black',
    $blackColor,
    $blackColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #2c0c38,
    #472653
  );

  /** Gray **/
  @include color('gray', $grayColor, $grayColorRgb, $blackColor, $blackColorRgb, #d4cece, #f2ecec);

  /** Light Gray **/
  @include color(
    'lightGray',
    $keyNeutralColor,
    $keyNeutralColorRgb,
    $blackColor,
    $blackColorRgb,
    #d4cece,
    #f2ecec
  );

  /** Purple **/
  @include color(
    'purple',
    $secondaryColorLight,
    $secondaryColorLightRgb,
    $whiteColor,
    $whiteColorRgb,
    $secondaryColorLightShade,
    #6c2759
  );

  /** Dark Purple **/
  @include color(
    'darkPurple',
    $darkPurpleColor,
    $darkPurpleColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #4c023e,
    #671b59
  );

  /** Pink **/
  @include color(
    'pink',
    $accentColorShade,
    $accentColorShadeRgb,
    $blackColor,
    $blackColorRgb,
    #e0807d,
    $accentColorTint
  );

  /** Light Pink **/
  @include color(
    'lightPink',
    $warningPinkColor,
    $warningPinkColorRgb,
    $warningPinkColor,
    $warningPinkColorRgb,
    #f5d6d6,
    #fbf5f5
  );

  /** Temperature **/
  @include color(
    'temperature',
    $excludedTrackerColor,
    $excludedTrackerColorRgb,
    $darkPurpleColor,
    $darkPurpleColorRgb,
    #e06562,
    #ff817d
  );

  /** Light Blue **/
  @include color(
    'lightBlue',
    $lightBlueColor,
    $lightBlueColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #99b3cc,
    #e0ecff
  );

  /** Gradient **/

  @include color(
    'gradient',
    linear-gradient(
      259deg,
      #ffb9f9 4.15%,
      #ffccfa 15.43%,
      #fff5f5 47.14%,
      #cce2ff 85.08%,
      #bad9ff 96.5%
    ),
    $secondaryColorDarkRgb,
    $darkPurpleColor,
    $darkPurpleColorRgb,
    $secondaryColorDarkShade,
    $secondaryColorDarkTint
  );
}

/**
* Custom purpose colors
*/
:root {
  /** Pregnant **/
  @include color(
    'pregnant',
    $pregnancyTestTrackerColor,
    $pregnancyTestTrackerColorRgb,
    $whiteColor,
    $whiteColorRgb,
    #5790da,
    #73adf9
  );

  /** LH **/
  @include color(
    'lh',
    $lhTestTrackerColor,
    $lhTestTrackerColorRgb,
    $lhTestTrackerColorContrast,
    $lhTestTrackerColorContrastRgb,
    #7ca7e0,
    #98c5ff
  );

  /** Pain **/
  @include color(
    'pain',
    $painTrackerColor,
    $painTrackerColorRgb,
    $painTrackerColorContrast,
    $painTrackerColorContrastRgb,
    #d57e98,
    #f39ab5
  );

  /** Skin **/
  @include color(
    'skin',
    $skinTrackerColor,
    $skinTrackerColorRgb,
    $skinTrackerColorContrast,
    $skinTrackerColorContrastRgb,
    #e0807d,
    #ff9d99
  );

  /** Mood **/
  @include color(
    'mood',
    $moodTrackerColor,
    $moodTrackerColorRgb,
    $secondaryColorLight,
    $secondaryColorLightRgb,
    #e0cb79,
    #fee996
  );

  /** Sex & Libido **/
  @include color(
    'sex',
    $sexTrackerColor,
    $sexTrackerColorRgb,
    $sexTrackerColorContrast,
    $sexTrackerColorContrastRgb,
    #df7f9e,
    #fd9bbc
  );

  /** Cervical Mucus **/
  @include color(
    'cervicalMucus',
    $cervicalMucusTrackerColor,
    $cervicalMucusTrackerColorRgb,
    $cervicalMucusTrackerColorContrast,
    $cervicalMucusTrackerColorContrastRgb,
    #be95cc,
    #dcb2ea
  );

  /** Bleeding **/
  @include color(
    'bleeding',
    $bleedingTrackerColor,
    $bleedingTrackerColorRgb,
    $bleedingTrackerColorContrast,
    $bleedingTrackerColorContrastRgb,
    #a673bb,
    #c48fd8
  );

  /** Sleep **/
  @include color(
    'sleep',
    $sleepTrackerColor,
    $sleepTrackerColorRgb,
    $sleepTrackerColorContrast,
    $sleepTrackerColorContrastRgb,
    #d1d5e6,
    #8c91ad
  );

  /** Baby feeding - breastfeeding **/
  @include color(
    'breastFeeding',
    $breastFeedingTrackerColor,
    $breastFeedingTrackerColorRgb,
    $breastFeedingTrackerColorContrast,
    $breastFeedingTrackerColorContrastRgb,
    #e0b4ba,
    #ffd2d7
  );

  /** Baby feeding - bottle-feeding **/
  @include color(
    'bottleFeeding',
    $bottleFeedingTrackerColor,
    $bottleFeedingTrackerColorRgb,
    $bottleFeedingTrackerColorContrast,
    $bottleFeedingTrackerColorContrastRgb,
    #64bcac,
    #80daca
  );

  /** Baby feeding - pumping **/
  @include color(
    'pumping',
    $pumpingTrackerColor,
    $pumpingTrackerColorRgb,
    $pumpingTrackerColorContrast,
    $pumpingTrackerColorContrastRgb,
    #e0a200,
    #ffbf1a
  );

  /** Ghost **/
  @include color(
    'ghost',
    $support1NeutralColor,
    $support1NeutralColorRgb,
    $blackColor,
    $blackColorRgb,
    #d4cece,
    #f2ecec
  );

  /** Oura **/
  @include color('oura', $ouraColor, $ouraColorRgb, $whiteColor, $whiteColorRgb, #264470, #4a6796);

  /** Layer tinted */
  @include color(
    'layerTinted',
    rgba(var(--ion-color-secondary-rgb), 0.1),
    rgba(var(--ion-color-secondary-rgb), 0.1),
    $blackColor,
    $blackColorRgb,
    rgba(var(--ion-color-secondary-rgb), 0.2),
    #f2ecec
  );
}

// Ideally we should shrink these custom colors to purposes instead of colors
// Prediction, LH, Pain is good examples
$customColors: (
  'white',
  'black',
  'gray',
  'lightGray',
  'purple',
  'darkPurple',
  'pink',
  'gradient',
  /* Purpose colors*/ 'temperature',
  'lh',
  'pain',
  'skin',
  'mood',
  'cervicalMucus',
  'bleeding',
  'sleep',
  'sex',
  'pregnant',
  'ghost',
  'oura',
  'layerTinted',
  'breastFeeding',
  'bottleFeeding',
  'pumping',
  'lightBlue'
);

@each $color in $customColors {
  .ion-color-#{$color} {
    --ion-color-base: var(--ion-color-#{$color}); // !important;
    --ion-color-base-rgb: var(--ion-color-#{$color}-rgb); // !important;
    --ion-color-contrast: var(--ion-color-#{$color}-contrast); // !important;
    --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb); // !important;
    --ion-color-shade: var(--ion-color-#{$color}-shade); // !important;
    --ion-color-tint: var(--ion-color-#{$color}-tint); // !important;
    //--background: var(--ion-color-#{$color}-background) !important;
    //--background-activated: var(--ion-color-#{$color}-background-activated) !important;
  }
}

:root {
  /**
  * Used by ngx-lib to match font sizes between WebSignup and NCApp3
  */
  --rem-base: 1.6;

  --ion-color-transparent: transparent;

  .ion-color-transparent {
    --ion-color-base: var(--ion-color-transparent);
    --ion-color-contrast: var(--ion-color-transparent);
    --ion-color-shade: var(--ion-color-transparent);
    --ion-color-tint: var(--ion-color-transparent);
    --background: var(--ion-color-transparent);
    color: var(--ion-text-color);
  }
}

/**
* Fertility colors
* --------------------------------------------------
* hex values for fertility colors to separate them from app colors
**/
:root {
  // Plan fertility scale
  --fert-color-RED1PLAN: #{$peakFertilityColor};
  --fert-color-RED2PLAN: #{$veryHighFertilityColor};
  --fert-color-RED3PLAN: #{$highFertilityColor};
  --fert-color-RED4PLAN: #{$mediumFertilityColor};
  --fert-color-RED5PLAN: #{$lowFertilityColor};
  --fert-color-RED6PLAN: #{$unlikelyFertilityColor};

  --fert-color-REDPLAN: #{$unlikelyFertilityColor};
  --fert-color-REDRECOVERY: #{$unlikelyFertilityColor};
  --fert-color-REDPREGNANT: #{$unlikelyFertilityColor};
  --fert-color-REDPREVENT: #{$useProtectionFertilityColor};
  --fert-color-REDPOSTPARTUM: #{$useProtectionFertilityColor};

  --fert-color-YELLOWPLAN: #{$moreDataNeededFertilityColor};
  --fert-color-YELLOWRECOVERY: #{$moreDataNeededFertilityColor};
  --fert-color-YELLOWPREGNANT: #{$moreDataNeededFertilityColor};
  --fert-color-YELLOWPREVENT: #{$useProtectionFertilityColor};

  --fert-color-PURPLEPLAN: #{$recoveryFertilityColor};
  --fert-color-PURPLERECOVERY: #{$recoveryFertilityColor};
  --fert-color-PURPLEPREGNANT: #{$recoveryFertilityColor};
  --fert-color-PURPLEPREVENT: #{$recoveryFertilityColor};
  --fert-color-PURPLEPOSTPARTUM: #{$recoveryFertilityColor};

  --fert-color-GREEN6PLAN: #{$notFertilePlanFertilityColor};
  --fert-color-GREENPLAN: #{$notFertilePlanFertilityColor};
  --fert-color-GREENRECOVERY: #{$notFertilePlanFertilityColor};
  --fert-color-GREENPREGNANT: #{$notFertilePlanFertilityColor};
  --fert-color-GREENPREVENT: #{$notFertilePreventFertilityColor};
  --fert-color-GREENPOSTPARTUM: #{$notFertilePreventFertilityColor};

  --fert-color-PREGNANT: #{$pregnantFertilityColor};

  // other
  --fert-color-Offline: #{$offlineFertilityColor};
  --fert-color-Recovery: #{$recoveryFertilityColor};
  --fert-color-NoData: #{$blackColor};
  --fert-color-OfflinePartner: #{$grayColor};
  --fert-color-Warning: #{$warningAlertColor};

  --fert-circle-text-color: var(--ion-color-black);
}

$fertilityColors: (
  'RED1PLAN',
  'RED2PLAN',
  'RED3PLAN',
  'RED4PLAN',
  'RED5PLAN',
  'RED6PLAN',
  'REDPLAN',
  'REDRECOVERY',
  'REDPREGNANT',
  'REDPREVENT',
  'YELLOWPLAN',
  'YELLOWRECOVERY',
  'YELLOWPREGNANT',
  'YELLOWPREVENT',
  'PURPLEPLAN',
  'PURPLERECOVERY',
  'PURPLEPREGNANT',
  'PURPLEPREVENT',
  'PURPLEPOSTPARTUM',
  'GREEN6PLAN',
  'GREENPLAN',
  'GREENRECOVERY',
  'GREENPREGNANT',
  'GREENPREVENT',
  'PREGNANT',
  'Offline',
  'Recovery',
  'Ghost',
  'NoData',
  'OfflinePartner',
  'Warning'
);

@each $color in $fertilityColors {
  .fertColor--#{$color} {
    --fertility-color: var(--fert-color-#{$color});
  }
}
