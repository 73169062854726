ion-content {
  pointer-events: auto; // might fix some scrolling issues on iOS

  // if the content is a direct child in an ion-page
  .ion-page > & {
    /** add safe-area-bottom as padding to ion-content on pages where there is no footer **/
    &:not(:has(+ ion-footer)) {
      --padding-bottom: max(var(--ion-safe-area-bottom, 0), 24px);
    }
  }
}
