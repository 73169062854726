@use 'mixins' as *;

ion-modal {
  &.show-modal::part(content) {
    // this is needed because of a bug where an inline modal is hidden if you open it a second time
    // couldn't find a better solution
    display: block !important;
  }

  &.modal-default {
    --height: 100%;
    --width: 100%;
  }

  &.modal--transparent {
    --background: transparent;
    --ion-background-color: transparent;
  }

  &.modal--darkTransparent {
    --background: transparent;

    &::part(backdrop) {
      background-color: rgba(26, 26, 26, 0.5);
    }

    &::part(content) {
      --height: auto;
      --max-height: 100%;

      position: absolute;
      bottom: 0;
    }
  }

  &.modal--alert {
    --max-width: 344px;

    padding: 0 16px;
    padding-top: calc(16px + 44px + var(--ion-safe-area-top));
    padding-bottom: calc(16px + 48px + var(--ion-safe-area-bottom));

    & > *:not(:first-child) {
      display: none !important;
    }

    &::part(content) {
      --height: auto;
      --max-height: 100%;

      display: flex !important;
      flex: 1 1 0;
      border-radius: 5px;
      overflow: visible;
    }

    .ion-page {
      position: relative;
      contain: content;
      display: flex;
      max-height: 100%;
      width: 100%;
    }

    // Overriding Ionic's style to make modals' content fit into modal's height
    .ion-page:not(ion-nav .ion-page) {
      height: auto;
    }

    ion-buttons {
      gap: 1rem;
      justify-content: flex-end;
      padding-top: 16px;

      .button-outline {
        color: var(--ion-color-secondary);
      }

      .button-solid:not(:hover) {
        --background: var(--ion-color-secondary);
      }
    }
  }

  &.modal--keyboard {
    padding-bottom: 0;

    &::part(content) {
      align-self: flex-end;

      .plt-desktop &,
      .plt-mobileweb & {
        align-self: center;
      }
    }

    .ion-page {
      transition: transform 300ms ease-in-out;
      transform: translateY(0);
      will-change: transform;

      .keyboard-open & {
        transform: translateY(calc((-1) * (16px + var(--keyboardHeight))));
      }
    }
  }

  &.modal--bottom {
    &::part(content) {
      align-self: flex-end;
      height: fit-content;
      padding-bottom: var(--ion-safe-area-bottom);
    }
  }

  &.modal--alwaysOnTop {
    z-index: 99999999999999999 !important;
  }

  &.modal--slides {
    --max-width: none !important;
    --box-shadow: none;

    padding-left: 0;
    padding-right: 0;

    &::part(content) {
      border-radius: 0;
    }
  }

  &.modal--snackbar {
    --max-width: none;

    margin: 0 16px;
    pointer-events: none;

    &::part(content) {
      bottom: calc(70px + var(--ion-safe-area-bottom)); // tabbar + margin 20px + safe area
      pointer-events: all;
      position: absolute;
    }

    &--todayPage {
      &::part(content) {
        bottom: calc(80px + var(--ion-safe-area-bottom)); // tabbar + fab + margin 20px + safe area
      }
    }

    &--secondary {
      ion-item {
        --padding-top: 0;
        --padding-bottom: 0;
        --background: var(--ion-color-secondary);

        ion-label {
          color: var(--ion-color-secondary-contrast) !important;
        }
      }
    }

    &--bottom {
      &::part(content) {
        bottom: calc(16px + var(--ion-safe-area-bottom));
      }
      ion-item {
        --padding-top: 0;
        --padding-bottom: 0;
      }
    }

    &--noBackdrop {
      &::part(backdrop) {
        pointer-events: none;
      }
    }

    &--withImg {
      ion-item {
        --padding-top: 0;
        --padding-bottom: 0;
        --background: var(--snackbar-withImg-background);

        ion-label {
          font-size: 14px !important;
          color: var(--snackbar-withImg-text) !important;
        }

        ion-button {
          color: var(--snackbar-withImg-button);
        }
      }

      &::part(backdrop) {
        pointer-events: none;
      }
    }
  }
}

@media only screen and (orientation: landscape) {
  ion-modal.modal--alert {
    --max-width: 440px;

    padding-top: calc(16px + var(--ion-safe-area-top));
    padding-bottom: calc(16px + var(--ion-safe-area-bottom));
  }
}

// override default styling
@media only screen and (height >= 600px) and (width >= 768px) {
  .sc-ion-modal-md-h:first-of-type {
    --box-shadow: none;
  }
}
